import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { PartBrand } from '../interface/part-brand';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class PartBrandService {
  private _isFromMatrix = true;


  constructor(
    private http: HttpClient,
    private _dataService: DataService
  ) { }

  public async register(brand: PartBrand): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/partBrand`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, JSON.stringify(brand), header).pipe(first()).toPromise();
    return resp['id'];
  }


  public async getById(id: string): Promise<PartBrand> {
    const url = `${environment.mkgoURL}/api/v1/partBrand/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.get(url, header).pipe(first()).toPromise();
    return resp;
  }


  public async getAll(): Promise<PartBrand[]> {
    const url = `${environment.mkgoURL}/api/v1/partBrand`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return (resp['partBrands'] as PartBrand[]).reverse();
  }


  public async update(brand: PartBrand) {
    const url = `${environment.mkgoURL}/api/v1/partBrand/${brand._id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.put(url, JSON.stringify(brand), header).pipe(first()).toPromise();
    return resp;
  }


  public async delete(id: string) {
    const url = `${environment.mkgoURL}/api/v1/partBrand/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.delete(url, header).pipe(first()).toPromise();
    return resp;
  }

}

